import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@/modules/core/components/Button'
import { Empty, theme } from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CheckOutlined,
  SearchOutlined,
  CloseOutlined
} from '@ant-design/icons'

/**
 * Props for the CustomTable component with server-side operations.
 */
interface CustomTableProps<T> {
  /** Array of data objects to be displayed in the table */
  data: T[]
  /** Total count of items from the server */
  totalCount: number
  /** Current page number */
  currentPage: number
  /** Number of items per page */
  itemsPerPage: number
  /** Function to handle page changes */
  onPageChange: (page: number) => void
  /** Function to handle search term changes */
  onSearchChange?: (searchTerm: string) => void
  /** Loading state indicator */
  isLoading?: boolean
  /** Array of column definitions with key, header, and optional width */
  columns: {
    key: keyof T
    header: string
    width?: string
    /** Optional render function to customize cell display */
    render?: (value: unknown, row: T) => React.ReactNode
  }[]
  /** Optional callback function triggered when a row is clicked */
  onRowClick?: (row: T) => void
  /** Configuration for action buttons */
  actionConfig?: {
    type: 'none' | 'custom' | 'edit' | 'delete' | 'view' | 'evaluation' | 'viewAndEdit'
    /** Optional callback functions triggered when specific buttons are clicked */
    onEdit?: (row: T) => void
    onDelete?: (row: T) => void
    onView?: (row: T) => void
    onEvaluate?: (row: T) => void
    /** Function to render custom action buttons for each row */
    renderActions?: (row: T, defaultButtons: Record<string, React.ReactNode>) => React.ReactNode
  }
  /** Optional array of column keys to enable filtering */
  filters?: {
    key: keyof T
    label?: string
  }[]
  /** Optional namespace for translations, defaults to 'common' */
  namespace?: string
  /** Determines if search functionality is shown */
  showSearch?: boolean
  /** Determines if the table header is shown */
  showHeader?: boolean
  /** Optional function to determine the CSS class for a row based on data */
  rowClassName?: (row: T) => string
  /** Current search term from parent (if any) */
  currentSearchTerm?: string
}

/**
 * Helper function to truncate text to a specified length and add ellipsis
 * @param text - The text to truncate
 * @param maxLength - Maximum length before truncation
 * @returns Truncated text with ellipsis if needed
 */
const truncateText = (text: string, maxLength = 25): string => {
  if (!text) return ''

  if (text.length <= maxLength) {
    return text
  }

  return text.substring(0, maxLength) + '...'
}

/**
 * A customizable table component with support for server-side filtering, pagination, and actions.
 *
 * @template T - The type of the data objects in the table
 * @param {CustomTableProps<T>} props - The properties passed to the component
 * @returns {JSX.Element} A table with pagination, filtering, and optional actions
 */
const CustomTable = <T extends object>({
  data,
  totalCount,
  currentPage,
  itemsPerPage,
  onPageChange,
  onSearchChange,
  isLoading = false,
  columns,
  onRowClick,
  actionConfig = { type: 'none' },
  namespace = 'common',
  showSearch = true,
  showHeader = true,
  rowClassName,
  currentSearchTerm = ''
}: CustomTableProps<T>): React.JSX.Element => {
  const { t, i18n } = useTranslation(namespace)
  const [selectedRows, setSelectedRows] = useState<number[]>([])
  const [searchTerm, setSearchTerm] = useState<string>(showSearch ? currentSearchTerm : '')
  const [appliedSearchTerm, setAppliedSearchTerm] = useState<string>(
    showSearch ? currentSearchTerm : ''
  )
  const isArabic = i18n.language === 'ar'
  const tableDir = isArabic ? 'rtl' : 'ltr'
  const {
    token: { colorTextDisabled }
  } = theme.useToken()

  // Update searchTerm when currentSearchTerm from parent changes
  useEffect(() => {
    if (showSearch) {
      setSearchTerm(currentSearchTerm)
      setAppliedSearchTerm(currentSearchTerm)
    }
  }, [currentSearchTerm, showSearch])

  // Calculate total pages based on total count
  const totalPages = Math.ceil(totalCount / itemsPerPage)

  // Handle search button click
  const handleSearch = () => {
    if (!showSearch || !onSearchChange) return

    // When search button is clicked, apply the current search term
    setAppliedSearchTerm(searchTerm)
    onSearchChange(searchTerm)
  }

  // Handle clear search
  const handleClearSearch = () => {
    if (!showSearch || !onSearchChange) return

    // When clear button is clicked, reset everything
    setSearchTerm('')
    setAppliedSearchTerm('')
    onSearchChange('') // This tells the parent to clear the search
  }

  // Handle Enter key press in search input
  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const createDefaultButtons = (row: T) => {
    const defaultButtons: Record<string, React.ReactNode> = {}

    if (actionConfig.type === 'edit' || actionConfig.type === 'viewAndEdit') {
      defaultButtons.edit = (
        <Button
          key="edit"
          buttonText=""
          icon={<EditOutlined style={{ fontSize: '1em' }} />}
          onClick={() => actionConfig.onEdit?.(row)}
          buttonType="default"
          className="flex items-center justify-center border border-[var(--color-border)] rounded-md px-1 py-1 text-[var(--color-text-placeholder)] hover:bg-[var(--color-surface)] w-17 mx-0.5"
        />
      )
    }

    if (actionConfig.type === 'delete') {
      defaultButtons.delete = (
        <Button
          key="delete"
          buttonText=""
          icon={<DeleteOutlined style={{ fontSize: '1em' }} />}
          onClick={() => actionConfig.onDelete?.(row)}
          buttonType="default"
          className="flex items-center justify-center border border-[var(--color-border)] rounded-md px-1 py-1 text-[var(--color-text-placeholder)] hover:bg-[var(--color-surface)] w-17 mx-0.5"
        />
      )
    }

    if (actionConfig.type === 'view' || actionConfig.type === 'viewAndEdit') {
      defaultButtons.view = (
        <Button
          key="view"
          buttonText=""
          icon={<EyeOutlined style={{ fontSize: '1em' }} />}
          onClick={() => actionConfig.onView?.(row)}
          buttonType="default"
          className="flex items-center justify-center border border-[var(--color-border)] rounded-md px-1 py-1 text-[var(--color-text-placeholder)] hover:bg-[var(--color-surface)] w-17 mx-0.5"
        />
      )
    }

    if (actionConfig.type === 'evaluation') {
      defaultButtons.evaluate = (
        <Button
          key="evaluate"
          buttonText=""
          icon={<CheckOutlined style={{ fontSize: '1em' }} />}
          onClick={() => actionConfig.onEvaluate?.(row)}
          buttonType="default"
          className="flex items-center justify-center border border-[var(--color-border)] rounded-md px-1 py-1 text-[var(--color-text-placeholder)] hover:bg-[var(--color-surface)] w-17 mx-0.5"
        />
      )
    }

    return defaultButtons
  }

  // Define columns with adjusted widths
  const checkboxColumn = {
    key: 'checkbox' as const,
    header: '',
    width: '40px' // Fixed width for checkbox column
  }

  // Setup columns and widths
  const actionColumn =
    actionConfig.type !== 'none'
      ? { key: 'actions' as const, header: t('actions'), width: '120px' } // Fixed width for actions
      : null

  // Function to render page numbers with ellipsis
  const renderPageNumbers = () => {
    const pages: React.JSX.Element[] = []
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <Button
            key={i}
            buttonText={String(i)}
            onClick={() => {
              onPageChange(i)
            }}
            buttonType={currentPage === i ? 'primary' : 'default'}
            className="px-3 py-1 rounded-lg"
          />
        )
      }
    } else {
      pages.push(
        <Button
          key={1}
          buttonText="1"
          onClick={() => {
            onPageChange(1)
          }}
          buttonType={currentPage === 1 ? 'primary' : 'default'}
          className="px-3 py-1 rounded-lg"
        />
      )
      if (currentPage > 3) {
        pages.push(
          <span key="start-ellipsis" className="px-3 py-1">
            ...
          </span>
        )
      }
      const start = Math.max(2, currentPage - 1)
      const end = Math.min(totalPages - 1, currentPage + 1)
      for (let i = start; i <= end; i++) {
        pages.push(
          <Button
            key={i}
            buttonText={String(i)}
            onClick={() => {
              onPageChange(i)
            }}
            buttonType={currentPage === i ? 'primary' : 'default'}
            className="px-3 py-1 rounded-lg"
          />
        )
      }
      if (currentPage < totalPages - 2) {
        pages.push(
          <span key="end-ellipsis" className="px-3 py-1">
            ...
          </span>
        )
      }
      pages.push(
        <Button
          key={totalPages}
          buttonText={String(totalPages)}
          onClick={() => {
            onPageChange(totalPages)
          }}
          buttonType={currentPage === totalPages ? 'primary' : 'default'}
          className="px-3 py-1 rounded-lg"
        />
      )
    }
    return pages
  }

  return (
    <div dir={tableDir}>
      <div className="mb-4">
        {/* Search Input with Buttons */}
        {showSearch && onSearchChange && (
          <div className="flex justify-start">
            <div className="flex w-full sm:w-2/5 items-center gap-2">
              {/* Search input */}
              <div className="w-1/2 relative">
                <input
                  type="text"
                  placeholder={t('search_placeholder')}
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value)
                  }}
                  onKeyDown={handleSearchKeyPress}
                  className="custom-select p-2 border border-[var(--color-border)] rounded-lg text-[var(--color-text-placeholder)] focus:outline-none focus:border-[var(--color-primary)] w-full text-sm h-10"
                />
              </div>

              {/* Search button */}
              <div className="w-1/4">
                <Button
                  buttonText={t('search')}
                  icon={<SearchOutlined style={{ fontSize: '14px' }} />}
                  onClick={handleSearch}
                  buttonType="primary"
                  className="p-2 rounded-lg flex items-center justify-center h-10 w-full"
                />
              </div>

              {/* Clear button */}
              <div className="w-1/4">
                <Button
                  buttonText={t('clear')}
                  icon={<CloseOutlined style={{ fontSize: '12px' }} />}
                  onClick={handleClearSearch}
                  buttonType="default"
                  className="p-2 rounded-lg flex items-center justify-center h-10 w-full"
                  disabled={!searchTerm && !appliedSearchTerm}
                />
              </div>
            </div>
          </div>
        )}

        {/* Applied search term indicator */}
        {appliedSearchTerm && (
          <div className="mt-2 flex items-center">
            <span className="text-sm text-[var(--color-text-placeholder)]">
              {t('search_results_for')}: <strong>{appliedSearchTerm}</strong>
            </span>
          </div>
        )}
      </div>

      {/* Table container with improved horizontal scrolling */}
      <div className="border-2 border-[var(--color-border)] rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table
            dir={tableDir}
            className="w-full bg-[var(--color-background)]"
            style={{
              minWidth: '100%',
              tableLayout: 'auto' // Changed from fixed to auto to respect content
            }}
          >
            {showHeader && (
              <thead>
                <tr>
                  <th
                    style={{ width: checkboxColumn.width }}
                    className="p-3 font-normal border-b border-[var(--color-border)] text-[var(--color-text-placeholder)] text-center"
                  >
                    {checkboxColumn.header}
                  </th>
                  {columns.map((col, colIndex) => (
                    <th
                      key={String(col.key)}
                      style={{
                        width: colIndex === 1 ? 'max-content' : (col.width ?? 'auto')
                      }}
                      className={`p-3 font-normal border-b border-[var(--color-border)] text-[var(--color-text-placeholder)] text-center ${
                        colIndex === 1 ? 'max-w-[200px] md:max-w-[300px]' : ''
                      }`}
                    >
                      {col.header}
                    </th>
                  ))}
                  {actionColumn && (
                    <th
                      style={{ width: actionColumn.width }}
                      className="p-3 font-normal border-b border-[var(--color-border)] text-[var(--color-text-placeholder)] text-center"
                    >
                      {actionColumn.header}
                    </th>
                  )}
                </tr>
              </thead>
            )}
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={columns.length + 2} className="p-0 border-b-0">
                    <div className="flex justify-center items-center py-10">
                      <p>{t('loading')}</p>
                    </div>
                  </td>
                </tr>
              ) : data.length > 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    onClick={() => onRowClick?.(row)}
                    className={`hover:bg-[var(--color-surface)] cursor-pointer border-b border-[var(--color-border)] ${
                      rowClassName ? rowClassName(row) : ''
                    }`}
                  >
                    {/* Checkbox column */}
                    <td className="p-3 text-center list-none">
                      <div className="flex justify-center items-center">
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(rowIndex)}
                          onChange={() => {
                            setSelectedRows((prev) =>
                              prev.includes(rowIndex)
                                ? prev.filter((id) => id !== rowIndex)
                                : [...prev, rowIndex]
                            )
                          }}
                          className="m-0"
                        />
                      </div>
                    </td>

                    {/* Data columns */}
                    {columns.map((col, colIndex) => (
                      <td
                        key={String(col.key)}
                        className={`p-3 text-[var(--color-text-base)] text-center ${
                          colIndex === 1
                            ? 'max-w-[200px] md:max-w-[300px] whitespace-nowrap overflow-hidden text-overflow-ellipsis'
                            : ''
                        }`}
                        title={String(row[col.key])}
                      >
                        {'render' in col && col.render
                          ? col.render(row[col.key], row)
                          : truncateText(String(row[col.key]), 25)}
                      </td>
                    ))}

                    {/* Action column */}
                    {actionConfig.type !== 'none' && (
                      <td className="p-3 text-center">
                        <div className="flex justify-center space-x-1">
                          {actionConfig.type === 'custom' && actionConfig.renderActions ? (
                            actionConfig.renderActions(row, createDefaultButtons(row))
                          ) : (
                            <>{Object.values(createDefaultButtons(row))}</>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length + 2} className="p-0 border-b-0">
                    <div className="py-10 flex justify-center items-center">
                      <Empty
                        description={
                          <span style={{ color: colorTextDisabled }}>{t('no_data_found')}</span>
                        }
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      {totalPages > 1 && (
        <div className={`mt-4 flex justify-start items-center space-x-2`}>
          <Button
            buttonText="<"
            onClick={() => {
              onPageChange(Math.max(currentPage - 1, 1))
            }}
            disabled={currentPage === 1}
            buttonType="default"
            className="px-3 py-1 border border-[var(--color-border)] rounded-lg text-[var(--color-text-base)] disabled:opacity-50"
          />
          {renderPageNumbers()}
          <Button
            buttonText=">"
            onClick={() => {
              onPageChange(Math.min(currentPage + 1, totalPages))
            }}
            disabled={currentPage === totalPages}
            buttonType="default"
            className="px-3 py-1 border border-[var(--color-border)] rounded-lg text-[var(--color-text-base)] disabled:opacity-50"
          />
        </div>
      )}
    </div>
  )
}

export default CustomTable
