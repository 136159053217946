import companyIcon from '@/assets/images/icons/companyIcon.svg'
import companyIconLight from '@/assets/images/icons/companyIconLight.svg'
import dashboardIcon from '@/assets/images/icons/dashboardIcon.svg'
import dashboardIconLight from '@/assets/images/icons/dashboardIconLight.svg'
import manageUserIcon from '@/assets/images/icons/ManageUserIcon.svg'
import manageUserIconLight from '@/assets/images/icons/ManageUserIconLight.svg'
import notificationIcon from '@/assets/images/icons/NotificationIcon.svg'
import notificationIconLight from '@/assets/images/icons/NotificationIconLight.svg'
import plansIcon from '@/assets/images/icons/plansIcon.svg'
import plansIconLight from '@/assets/images/icons/plansIconLight.svg'
import settingIcon from '@/assets/images/icons/settingIcon.svg'
import settingIconLight from '@/assets/images/icons/settingIconLight.svg'
import taskIcon from '@/assets/images/icons/taskIcon.svg'
import taskIconLight from '@/assets/images/icons/taskIconLight.svg'
import seasonIcon from '@/assets/images/icons/seasonIcon.svg'
import seasonIconLight from '@/assets/images/icons/seasonIconLight.svg'

export interface CustomMenuItem {
  key: string
  icon: (selectedKey: string) => React.ReactNode
  label: string
  path: string
}

/**
 * Generates sidebar menu items for Admin.
 *
 * Each menu item includes an icon that changes based on selection,
 * a translated label, a path for navigation
 *
 * @param t - Translation function for localizing labels
 * @returns List of menu items configured for Business Users
 */
export const getAdminMenuItems = (t: (key: string) => string): CustomMenuItem[] => [
  {
    key: '1',
    icon: (selectedKey) => (
      <img
        src={selectedKey === '1' ? dashboardIconLight : dashboardIcon}
        className="h-5 w-5"
        alt="Dashboard"
      />
    ),
    label: t('sidebar.dashboard'),
    path: ''
  },
  {
    key: '2',
    icon: (selectedKey) => (
      <img
        src={selectedKey === '2' ? seasonIconLight : seasonIcon}
        className="h-5 w-5"
        alt="seasons"
      />
    ),
    label: t('sidebar.seasons'),
    path: 'seasons'
  },
  {
    key: '3',
    icon: (selectedKey) => (
      <img src={selectedKey === '3' ? taskIconLight : taskIcon} className="h-5 w-5" alt="tasks" />
    ),
    label: t('sidebar.tasksTraking'),
    path: 'tasks-tracking'
  },
  {
    key: '4',
    icon: (selectedKey) => (
      <img
        src={selectedKey === '4' ? companyIconLight : companyIcon}
        className="h-5 w-5"
        alt="company"
      />
    ),
    label: t('sidebar.companies'),
    path: 'company'
  },
  {
    key: '5',
    icon: (selectedKey) => (
      <img src={selectedKey === '5' ? plansIconLight : plansIcon} className="h-5 w-5" alt="plans" />
    ),
    label: t('sidebar.plans'),
    path: 'plans'
  },
  {
    key: '6',
    icon: (selectedKey) => (
      <img
        src={selectedKey === '6' ? notificationIconLight : notificationIcon}
        className="h-5 w-5"
        alt="notifications"
      />
    ),
    label: t('sidebar.notifications'),
    path: 'notifications'
  },
  {
    key: '7',
    icon: (selectedKey) => (
      <img
        src={selectedKey === '7' ? manageUserIconLight : manageUserIcon}
        className="h-5 w-5"
        alt="users"
      />
    ),
    label: t('sidebar.manageUsers'),
    path: 'users'
  },
  {
    key: '8',
    icon: (selectedKey) => (
      <img
        src={selectedKey === '8' ? settingIconLight : settingIcon}
        className="h-5 w-5"
        alt="settings"
      />
    ),
    label: t('sidebar.settings'),
    path: 'settings'
  }
]

/**
 * Generates sidebar menu items for Business Users.
 *
 * Each menu item includes an icon that changes based on selection,
 * a translated label, a path for navigation
 *
 * @param t - Translation function for localizing labels
 * @returns List of menu items configured for Business Users
 */
export const getBusinessUserMenuItems = (t: (key: string) => string): CustomMenuItem[] => [
  {
    key: '1',
    icon: (selectedKey) => (
      <img
        src={selectedKey === '1' ? dashboardIconLight : dashboardIcon}
        className="h-5 w-5"
        alt="Dashboard"
      />
    ),
    label: t('sidebar.dashboard'),
    path: ''
  },
  {
    key: '55',
    icon: (selectedKey) => (
      <img src={selectedKey === '55' ? taskIconLight : taskIcon} className="h-5 w-5" alt="tasks" />
    ),
    label: t('sidebar.tasks'),
    path: 'tasks'
  },
  {
    key: '6',
    icon: (selectedKey) => (
      <img
        src={selectedKey === '6' ? notificationIconLight : notificationIcon}
        className="h-5 w-5"
        alt="notifications"
      />
    ),
    label: t('sidebar.notifications'),
    path: 'notifications'
  },
  {
    key: '8',
    icon: (selectedKey) => (
      <img
        src={selectedKey === '8' ? settingIconLight : settingIcon}
        className="h-5 w-5"
        alt="settings"
      />
    ),
    label: t('sidebar.settings'),
    path: 'settings'
  }
]

/**
 * Generates sidebar menu items for Guesta.
 *
 * Each menu item includes an icon that changes based on selection,
 * a translated label, a path for navigation.
 *
 * @param t - Translation function for localizing labels
 * @returns List of menu items configured for Guest
 */
export const getGuestMenuItems = (t: (key: string) => string): CustomMenuItem[] => [
  {
    key: '1',
    icon: (selectedKey) => (
      <img
        src={selectedKey === '1' ? dashboardIconLight : dashboardIcon}
        className="h-5 w-5"
        alt="Dashboard"
      />
    ),
    label: t('sidebar.dashboard'),
    path: ''
  }
]
