import React, { useEffect } from 'react'
import { Typography, theme } from 'antd'
import { FieldTimeOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import DashboardLayout from '@/modules/core/layouts/DashboardLayout'

const { Title } = Typography

/**
 * UnderConstructionPage displays a message indicating the page is under construction
 * @returns {JSX.Element} Rendered UnderConstructionPage component
 */
const UnderConstructionPage: React.FC = () => {
  const { t } = useTranslation('core')
  const {
    token: { colorTextPlaceholder, colorTextSecondary }
  } = theme.useToken()

  useEffect(() => {
    const originalOverflow = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = originalOverflow
    }
  }, [])

  return (
    <DashboardLayout>
      <div
        className="flex items-center justify-center text-center w-full"
        style={{
          height: 'calc(100vh - 200px)',
          overflow: 'hidden'
        }}
      >
        <div>
          <div className="relative w-34 h-34 mx-auto flex items-center justify-center">
            <div
              className="absolute inset-0 rounded-full"
              style={{ backgroundColor: `${colorTextSecondary}1A` }}
            />
            <div className="w-24 h-24 bg-white rounded-full shadow-lg flex items-center justify-center z-10">
              <FieldTimeOutlined
                className="text-5xl"
                style={{ fontWeight: 600, color: colorTextSecondary }}
              />
            </div>
          </div>

          <Title level={2} className="mt-8 mb-4 font-medium text-2xl">
            {t('under_construction.title')}
          </Title>

          <Typography.Text
            className="text-base"
            style={{ color: colorTextPlaceholder, fontWeight: 500 }}
          >
            {t('under_construction.subTitle')}
          </Typography.Text>
        </div>
      </div>
    </DashboardLayout>
  )
}

export default UnderConstructionPage
