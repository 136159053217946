/* 
for reference: https://ant.design/docs/customize-theme
*/
import { theme } from 'antd'
import type { ThemeConfig } from 'antd'

// Common token settings for both themes
const commonTokens = {
  borderRadius: 10,
  fontFamily: 'TajawalRegular, sans-serif',
  fontWeightStrong: 800,
  fontWeightMedium: 600,
  fontWeightLight: 300
}

// Light theme configuration
export const lightTheme: ThemeConfig = {
  algorithm: theme.defaultAlgorithm,
  token: {
    ...commonTokens,
    colorPrimary: '#00A1FF',
    colorBgBase: '#f5f5f5',
    colorBgContainer: '#ffffff',
    colorTextBase: '#002B62',
    colorText: '#002B62',
    colorTextHeading: '#002B62',
    colorTextDescription: '#002B62',
    colorTextSecondary: '#a9a8a8',
    colorTextDisabled: '#a9a8a8',
    colorTextPlaceholder: '#a9a8a8',
    colorSuccess: '#058108',
    colorError: '#a9000e',
    colorLink: '#00A1FF',
    colorBorder: '#3a608f',
    borderRadius: 8
  }
}

// Dark theme configuration
export const darkTheme: ThemeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    ...commonTokens,
    colorPrimary: '#00A1FF',
    colorBgBase: '#191c20',
    colorBgContainer: '#212326',
    colorTextBase: '#fefefe',
    colorText: '#fefefe',
    colorTextHeading: '#fefefe',
    colorTextDescription: '#a9a8a8',
    colorTextSecondary: '#a9a8a8',
    colorTextDisabled: '#a9a8a8',
    colorTextPlaceholder: '#a9a8a8',
    colorSuccess: '#058108',
    colorError: '#c00011',
    colorLink: '#3082b1',
    colorBorder: '#5a86bc',
    borderRadius: 8
  }
}

/**
 * Returns the appropriate Ant Design theme configuration based on dark mode preference
 * @param {boolean} isDark - Whether dark mode is enabled
 * @returns {ThemeConfig} The corresponding theme configuration
 */
export const getThemeConfig = (isDark: boolean): ThemeConfig => {
  return isDark ? darkTheme : lightTheme
}

export const themeConfig = {
  token: {
    ...commonTokens,
    colorPrimary: '#1A784A' /* Primary color (dark green) */,
    colorSecondary: '#B88841' /* Primary color (yellow) */,

    colorBgBase: '#FFFFFF' /* Page background (white) */,
    colorBgContainer: '#FFFFFF' /* Container background (white) */,
    colorBorder: '#A0A8B5' /* Border color (gray) */,
    colorSplit: '#A0A8B51A' /* Split color for dividers (light gray)*/,

    //texts color
    colorTextBase: '#2F2F2F' /* Base text color (dark gray) */,
    colorTextHeading: '#2F2F2F' /* Heading text color (dark gray) */,
    colorTextDescription: '#B88841' /* Description text color (secondary highlight) */,
    colorTextSecondary: '#B88841' /* Secondary text color (highlight) */,
    colorTextDisabled: '#A0A8B5' /* Disabled text color (light gray) */,
    colorTextPlaceholder: '#A0A8B5' /* Placeholder text color (light gray) */,
    colorLink: '#1A784A' /* Link color (yellow) */,
    colorPrimaryTextHover: '#1A784A' /* Hover text (dark green) */,

    //status color
    colorSuccess: '#1A784A' /* Success color (dark green) */,
    colorError: '#CD2020' /* Error color (red) */,
    colorWarning: '#B88841' /*Warning color (yellow) */,
    colorInfo: '#A0A8B5' /*Warning color (gray) */,

    // Button states
    Button: {
      colorPrimary: '#1A784A' /* color for primary button */,
      colorPrimaryHover: '#15633D' /* Hover state for primary button */,
      colorDelete: '#CD2020' /* color for delete button */,
      colorDeleteHover: '#C21C1C' /* Hover state for delete button */,
      colorDisabled: '#A0A8B5' /* color for disabled button */,
      BorderColorLined: '#A0A8B5' /* color for lined button */,
      colorLinedHover: '#A0A8B5' /* Hover state for primary button */
    },

    // sidebar
    // Sidebar: {
    //   ItemContainerCN: 'w-[230px] h-11 rounded-[7.27px] bg-pure-white shadow-none',
    //   ItemSubContainerCN: 'flex items-center justify-end gap-2 h-11 px-[22px]',
    //   ItemTextCN:
    //     'font-body-small text-black text-[length:var(--body-small-font-size)] tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] [font-style:var(--body-small-font-style)]',
    //   ItemIconCN: 'w-[18px] h-[18px]',
    //   //selected item
    //   ItemActiveContainerCN: 'w-[230px] h-11 rounded-[8px] p-0 justify-start',
    //   ItemActiveSubContainerCN: 'flex w-full items-center justify-end gap-2 px-[22px]',
    //   ItemActiveTextCN:
    //     'font-body-small font-[number:var(--body-small-font-weight)] text-white text-[length:var(--body-small-font-size)] tracking-[var(--body-small-letter-spacing)] leading-[var(--body-small-line-height)] whitespace-nowrap [font-style:var(--body-small-font-style)]',
    //   ItemActiveIconCN: 'w-[22px] h-[22px] text-white'
    // },

    PageTitle: {
      containerCN: 'w-full flex justify-start mb-4',
      textCN: 'text-[var(--color-text-base)] text-2xl font-medium text-center py-2 px-0'
    },

    // Form elements
    FormCN: {
      ContainerCN: 'w-full max-w-md md:max-w-lg lg:max-w-xl px-4 sm:px-0 my-4',
      ItemCN: 'w-full relative',
      LabelCN:
        ' text-[var(--color-text-base)] block text-start w-full font-medium text-lg md:text-xl text-black tracking-normal leading-tight mb-0',
      FieldCN:
        'w-full text-sm p-2 bg-transparent outline-none text-start text-base tracking-normal',

      //row form item
      ContainerRowCN: 'w-full',
      ItemRowCN: 'flex flex-col sm:flex-row items-start sm:items-start mb-1',
      LabelRowCN:
        'text-[var(--color-text-base)] font-medium mb-2 sm:mb-0 sm:w-44 sm:text-start sm:pe-4 break-words',

      FieldRowCN:
        'w-full border-[var(--color-text-placeholder)] rounded-md py-2 px-2 outline-none bg-transparent',
      SectionHeadingCN: 'text-[var(--color-text-placeholder)] font-medium mt-0 mb-3',

      PhoneInputWrapperCN:
        'flex items-center border border-[var(--color-text-placeholder)] rounded-md bg-transparent overflow-hidden',
      PhonePrefixCN:
        'px-3 text-sm text-[var(--color-text-placeholder)] bg-transparent border-r border-[var(--color-text-placeholder)] select-none',
      PhoneFieldCN: 'w-full py-2 px-2 outline-none bg-transparent text-left border-none',

      FormWrapperCN: 'w-full',
      FieldContainerCN: 'w-full sm:flex-1',
      ActionButtonsCN: 'flex justify-start gap-4 mt-8'
    },

    InfoCard: {
      ContainerCN: 'w-full max-w-full sm:max-w-[400px] md:max-w-[431px] border-none shadow-none',
      SubContainerCN: 'flex flex-col w-full',
      HeaderContainerCN: 'flex items-start w-full mb-3 sm:mb-4',
      HeaderCN: 'text-sm font-medium',

      InfoContainerCN: 'flex flex-col gap-4 w-full',
      ElementContainer: 'flex justify-between w-full',
      InfoLabelAndIconContainerCN: 'flex items-center gap-2',
      InfoLabelCN: 'text-[var(--color-text-base)] text-sm font-medium',
      InfoIconCN: 'h-5 w-5 text-[var(--color-text-placeholder)]',
      InfoValueCN: 'text-[var(--color-text-placeholder)] text-start'
    }
  }
}
