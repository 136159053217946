import { useNavigate } from 'react-router-dom'
import i18n from 'i18next'

/**
 * A hook that returns a function to navigate to a route with the current
 * language prepended to the route.
 *
 * @returns A function that takes an optional route string, and navigates to
 * the route with the current language prepended to it.
 *
 * @example
 * const navigateTo = useNavigateTo()
 * navigateTo('programs')
 * // Navigates to '/ar/programs' if the current language is 'ar'
 */
export const useNavigateTo = () => {
  const navigate = useNavigate()
  const currentLanguage = i18n.resolvedLanguage ?? 'ar'

  const navigateTo = (route = '') => {
    void navigate(`/${currentLanguage}/${route}`)
  }

  return navigateTo
}
