import axiosClient from '@/api/axiosInstance'
import { AUTH } from '@/api/endpoints'
import { APP_CONFIG } from '@/config/app.config'
import {
  SignInPayload,
  VerifyOtpPayload,
  AuthSuccessResponse,
  SignInOtpResponse
} from '../types/auth.types'

const { STORAGE_KEYS } = APP_CONFIG.AUTH

/**
 * Handles user sign-in step 1: sends credentials to the backend and receives OTP.
 * @param {SignInPayload} payload - The user's ssn and password.
 * @returns {Promise<SignInOtpResponse>} The response with OTP status.
 */
export const signIn = async (payload: SignInPayload): Promise<SignInOtpResponse> => {
  try {
    const api = axiosClient()
    const response = await api.post<SignInOtpResponse>(AUTH.SIGN_IN, payload)
    return response.data
  } catch {
    throw new Error('Failed to login plaeas try again later')
  }
}

/**
 * Handles user sign-in step 2: verifies OTP and completes authentication.
 * @param {VerifyOtpPayload} payload - The user's ssn and OTP code.
 * @returns {Promise<AuthSuccessResponse>} The authentication tokens and user info.
 */
export const verifyLoginOtp = async (payload: VerifyOtpPayload): Promise<AuthSuccessResponse> => {
  try {
    const api = axiosClient()
    const response = await api.post<AuthSuccessResponse>(AUTH.SIGN_IN_VERIFY, payload)
    return response.data
  } catch {
    throw new Error('Failed to verify login otp')
  }
}

/**
 * Handles user sign-out by sending the refresh token to the backend for blacklisting.
 * Removes tokens from localStorage after the sign-out process.
 * @returns {Promise<void>} A promise that resolves when the sign-out process is complete.
 */
export const signOut = async (): Promise<void> => {
  const api = axiosClient()
  const refreshToken = localStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN) // Retrieve the refresh token

  try {
    // Send the refresh token to the backend for blacklisting
    if (refreshToken) {
      await api.post(AUTH.SIGN_OUT, { refresh: refreshToken })
    }
  } catch {
    // Silent failure - we still want to clear the tokens even if the API call fails
    // You could add telemetry or logging service here if needed
  } finally {
    // Clear tokens from localStorage regardless of server response
    localStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN)
    localStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN)
    // localStorage.removeItem(STORAGE_KEYS.USER_EMAIL)
    localStorage.removeItem(STORAGE_KEYS.USER_INFO)
    localStorage.removeItem(STORAGE_KEYS.OTP_VERIFIED)
  }
}
