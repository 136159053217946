import React from 'react'
import AddSeasonForm from '../components/AddSeasonForm'
import DashboardLayout from '@/modules/core/layouts/DashboardLayout'
import { useTranslation } from 'react-i18next'

/**
 * AddSeasonPage Component
 *
 * Renders the AddSeason page using the DashboardLayout and AddSeasonForm components.
 *
 * @returns {JSX.Element} The rendered AddSeason page.
 */
const AddSeasonPage: React.FC = () => {
  const { t } = useTranslation(['season'])
  return (
    <DashboardLayout>
      <h2 className=" text-start text-2xl tracking-tight text-gray-900">{t('addNewSeason')}</h2>
      <div>
        <AddSeasonForm />
      </div>
    </DashboardLayout>
  )
}

export default AddSeasonPage
