import { RuleObject } from 'antd/es/form'
import { StoreValue } from 'antd/es/form/interface'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

// Define the form field types
export interface AddSeasonFormFields {
  hijri_year: number
  gregorian_year: number
}
/**
 * *
 * @param messages - Validation messages in the appropriate language
 * @returns A Zod schema for validating add-season form data.
 */
export const createSeasonValidationSchema = (messages: ValidationMessages) => {
  return z.object({
    hijri_year: z
      .string({
        required_error: messages.hijri_year.required
      })
      .regex(/^\d{4}$/, messages.hijri_year.invalid)
      .refine((val) => parseInt(val) >= 1440, {
        message: messages.hijri_year.invalid
      }),
    gregorian_year: z
      .string({
        required_error: messages.gregorian_year.required
      })
      .regex(/^\d{4}$/, messages.gregorian_year.invalid)
      .refine((val) => parseInt(val) >= 2020, {
        message: messages.gregorian_year.invalid
      })
  })
}

// Define the return type of the schema creation function
export type AddSeasonSchema = ReturnType<typeof createSeasonValidationSchema>

// Types for the messages
interface ValidationMessages {
  hijri_year: {
    required: string
    invalid: string
  }
  gregorian_year: {
    required: string
    invalid: string
  }
}

/**
 * Creates a field validator function compatible with Ant Design Form validation.
 *
 * @param schema - The Zod schema for form validation
 * @param fieldName - The name of the field being validated
 * @returns An Ant Design compatible validator function
 */
export const createFieldValidator = (
  schema: AddSeasonSchema,
  fieldName: keyof AddSeasonFormFields
) => {
  return async (_rule: RuleObject, value: StoreValue): Promise<void> => {
    const fieldSchema = schema.shape[fieldName]

    try {
      await fieldSchema.parseAsync(value)
    } catch (error) {
      if (error instanceof z.ZodError) {
        throw new Error(error.errors[0].message)
      }
      throw new Error('Validation failed')
    }
  }
}

/**
 * Retrieves validation messages using i18n translation keys.
 *
 * @returns Validation messages in the appropriate language
 */
export const useValidationMessages = (): ValidationMessages => {
  const { t } = useTranslation(['season'])

  return {
    hijri_year: {
      required: t('hijiryPlaceHolder'),
      invalid: t('hijiryInvalid')
    },
    gregorian_year: {
      required: t('gregorianPlaceHolder'),
      invalid: t('gregorianInvalid')
    }
  }
}
