import { useState, useEffect } from 'react'
import { Layout, theme, Dropdown, Button as AntButton } from 'antd'
import { MenuOutlined, UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from './LanguageSwitcher'
import SignOutButton from '@auth/components/SignOutButton'
// import ThemeToggle from '../components/ThemeToggle'
import { APP_CONFIG } from '@/config/app.config'

const { Header } = Layout
const { STORAGE_KEYS } = APP_CONFIG.AUTH

interface TopbarProps {
  isMobile: boolean
  collapsed: boolean
  onToggleSidebar: () => void
}

interface User {
  id: number
  username: string
  type: string
  email: string
}

/**
 * Header component with user profile
 *
 * @returns {React.ReactElement} Topbar with user info
 */
export default function Topbar({ isMobile, onToggleSidebar }: TopbarProps) {
  const {
    token: { colorBgContainer }
  } = theme.useToken()
  const { t, i18n } = useTranslation('auth')
  const navigate = useNavigate()

  // New state for user info with type definition
  const [user, setUser] = useState<User | null>(null)

  // Read the user info from localStorage on mount.
  useEffect(() => {
    const storedUser = localStorage.getItem(STORAGE_KEYS.USER_INFO)
    if (storedUser) {
      try {
        setUser(JSON.parse(storedUser) as User)
      } catch {
        // Silent error handling - failed to parse user info
      }
    }
  }, [])

  const handleMenuClick = ({ key }: { key: string }) => {
    if (key === 'profile' && user?.id) {
      const currentLang = i18n.language
      void navigate(`/${currentLang}/profile`)
    }
  }

  // Build the profile dropdown menu items.
  const profileMenuItems = [
    ...(user?.type === 'BUSINESS_USER'
      ? [
          {
            key: 'profile',
            icon: <UserOutlined />,
            label: t('profile')
          }
        ]
      : []),
    {
      key: 'logout',
      label: <SignOutButton />
    }
  ]

  return (
    <Header
      className="sticky top-0 z-10 flex items-center h-20 px-4 border-b-[0.01rem]"
      style={{
        background: colorBgContainer
      }}
    >
      {/* 
        Show the toggle button only if on mobile
      */}
      {isMobile && (
        <AntButton
          className="!p-4  border-[0.01rem] rounded-md hover:!border-gray-400 focus:!outline-none"
          type="text"
          icon={<MenuOutlined />}
          onClick={onToggleSidebar}
          style={{ fontSize: 20, marginRight: 8 }}
        />
      )}

      {/* spacer */}
      <div className="flex-1" />

      {/* Profile area */}
      <div className="flex items-center gap-4">
        {/* <ThemeToggle /> */}
        <LanguageSwitcher />
        <Dropdown.Button
          menu={{ items: profileMenuItems, onClick: handleMenuClick }}
          trigger={['click']}
          placement="bottomRight"
          icon={<UserOutlined />}
        >
          {user?.username ?? t('guest')}
        </Dropdown.Button>
        <span className="text-sm font-medium text-primary"></span>
      </div>
    </Header>
  )
}
