import React, { useState, useCallback, useEffect } from 'react'
import { Alert, Button, Flex, Form, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { addSeason } from '../services/season.services'
import { z } from 'zod'
import i18n from '@/i18n/config'
import {
  createSeasonValidationSchema,
  createFieldValidator,
  useValidationMessages
} from '../validations/AddSeasonValidation'

/**
 * Fields required for adding a new season.
 */
interface SeasonFieldType {
  name: string
  hijri_year: string
  gregorian_year: string
  note: string
  is_active: boolean
}

/**
 * AddSeasonForm component handles creating a new season.
 *
 * @description
 * This component features:
 * - Localized form fields and validation messages using react-i18next
 * - Zod schema validation for form inputs
 * - Error handling for API calls and validation errors
 *
 * @returns {React.ReactElement} A form component for adding a new season
 */
const AddSeasonForm: React.FC = () => {
  const { t } = useTranslation(['season'])
  const [form] = Form.useForm()
  const [messageApi, contextHolder] = message.useMessage()
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const validationMessages = useValidationMessages()
  const validationSchema = createSeasonValidationSchema(validationMessages)

  const processError = useCallback(
    (error: unknown) => {
      if (error instanceof z.ZodError) {
        setErrorMsg(error.errors[0].message)
      } else if (error instanceof Error) {
        setErrorMsg(error.message)
      } else {
        setErrorMsg(t('unknownError', 'حدث خطأ غير معروف'))
      }
    },
    [t]
  )

  const handleFinish = useCallback(
    (values: SeasonFieldType) => {
      void (async () => {
        setErrorMsg(null)
        setLoading(true)

        try {
          const { hijri_year, gregorian_year, name, note } = values
          validationSchema.parse({ hijri_year, gregorian_year })

          await addSeason({
            name,
            hijri_year: parseInt(hijri_year),
            gregorian_year: parseInt(gregorian_year),
            note,
            is_active: true
          })

          message.success(t('season_added_successfully'))
          form.resetFields()
          void navigate(`/${i18n.resolvedLanguage ?? 'ar'}/seasons`)
        } catch (error: unknown) {
          processError(error)
        } finally {
          setLoading(false)
        }
      })()
    },
    [form, validationSchema, navigate, messageApi, t, processError]
  )

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      {contextHolder}
      {errorMsg && (
        <Alert
          message={errorMsg}
          type="error"
          closable
          onClose={() => {
            setErrorMsg(null)
          }}
          className="mb-4"
        />
      )}

      <Form<SeasonFieldType>
        form={form}
        name="addSeason"
        initialValues={{}}
        onFinish={handleFinish}
        autoComplete="off"
      >
        <Form.Item name="name" rules={[{ required: true, message: t('seasonNamePlaceHolder') }]}>
          <Flex
            wrap
            gap="middle"
            justify="flex-start"
            vertical={isMobile}
            className="mt-10 w-full md:w-2/3"
          >
            <label style={{ flex: 1 }}>{t('seasonName')}</label>
            <Input
              placeholder={t('seasonNamePlaceHolder')}
              className="px-3 py-2 flex-1"
              style={{ flex: 2 }}
            />
          </Flex>
        </Form.Item>

        <Form.Item
          name="hijri_year"
          rules={[
            {
              validator: createFieldValidator(validationSchema, 'hijri_year')
            }
          ]}
        >
          <Flex
            wrap
            gap="middle"
            justify="flex-start"
            vertical={isMobile}
            className="mt-3 w-full md:w-2/3"
          >
            <label style={{ flex: 1 }}>{t('hijiry')}</label>
            <Input
              type="number"
              placeholder={t('hijiryPlaceHolder')}
              className="px-3 py-2"
              style={{ flex: 2 }}
            />
          </Flex>
        </Form.Item>

        <Form.Item
          name="gregorian_year"
          rules={[
            {
              validator: createFieldValidator(validationSchema, 'gregorian_year')
            }
          ]}
        >
          <Flex
            wrap
            gap="middle"
            justify="flex-start"
            vertical={isMobile}
            className="mt-3 w-full md:w-2/3"
          >
            <label style={{ flex: 1 }}>{t('gregorian')}</label>
            <Input
              type="number"
              placeholder={t('gregorianPlaceHolder')}
              className="px-3 py-2"
              style={{ flex: 2 }}
            />
          </Flex>
        </Form.Item>

        <Form.Item name="note">
          <Flex
            wrap
            gap="middle"
            justify="flex-start"
            vertical={isMobile}
            className="mt-3 w-full md:w-2/3"
          >
            <label style={{ flex: 1 }}>{t('notes')}</label>
            <Input.TextArea placeholder={t('notesPlaceHolder')} style={{ flex: 2 }} />
          </Flex>
        </Form.Item>

        <Flex
          wrap
          gap="middle"
          justify="flex-start"
          vertical={isMobile}
          className="mt-7 w-full md:w-2/3"
        >
          <Button
            data-testid="submit-button"
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ flex: 1 }}
          >
            {t('addNewSeason')}
          </Button>
          <Button
            type="default"
            href={`/${i18n.resolvedLanguage ?? 'ar'}/seasons`}
            style={{ flex: 1 }}
          >
            {t('cancel')}
          </Button>
        </Flex>
      </Form>
    </>
  )
}

export default AddSeasonForm
