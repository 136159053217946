import React from 'react'
import { Navigate } from 'react-router-dom'

interface CompnayRouteProps {
  children: React.JSX.Element
}

/**
 * ProtectedRoute component ensures that only authenticated users can access certain routes.
 * If the user is not authenticated (i.e., no ACCESS_TOKEN in localStorage),
 * it redirects them to the login page.
 *
 * @param {object} props - The component props.
 * @param {React.JSX.Element} props.children - The child element(s) to render when authenticated.
 * @returns {React.JSX.Element} The child element(s) if authenticated, otherwise a redirection to the login page.
 */
const CompnayRoute: React.FC<CompnayRouteProps> = ({ children }) => {
  // Check if the user is authenticated by verifying if the token exists.
  const token = localStorage.getItem('ACCESS_TOKEN')
  const userType = localStorage.getItem('USER_TYPE')
  if (!token) {
    return <Navigate to="/ar/login" replace />
  }
  if (userType != 'BUSINESS_USER') {
    return <Navigate to="/" replace />
  }

  return children
}

export default CompnayRoute
